<template>
  <div class="photo_exhibition">
    <div class="text">
      <div class="box">
        <img :src="pic" alt="" v-if="pic">
        <div v-else>图片消失啦！~ </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import {get_notice} from '@/https/api.js';
export default {
  name:'photo_exhibition',
  data(){
    return{
      pic:''
    }
  },
  methods:{
    aviod_img(){
      let params = {
        id:this.$route.query.id
      };
      get_notice(params).then(res => {
        this.pic = res.data.url;
      })
    }
  },
  created(){
    this.aviod_img()
  }
}
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.text{
  width: 100%;
  background-color: #fafcff;
  @include flex-center();
  .box{
    @include flex-column-left() ;
    padding: 20px 0;
    img{
      border-radius: 4px
    }
  }
}
</style>